<template>
  <div>
    AfterLoginRedirect
  </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";

export default {
  components: {
    // Register your components here
  },
  mixins: [AuthenticatedMixin],
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
